import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchWrapper} from "../_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

//create slice
const name = 'companies';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

//exports
export const companiesActions = {...slice.actions, ...extraActions};
export const companiesReducer = slice.reducer;


//implementations
function createInitialState() {
    return {
        // company: {},
        companies: []
    }
}

function createReducers() {

    return {
        _addCompany,
        _deleteCompany
    }

    function _addCompany(state, action) {

        let b = true;

        const _companies = state.companies.map(company => {

            if (company.id === action.payload.id) {
                company = action.payload;
                b = false;
            }
            return company;
        });

        if (!b)
            state.companies = _companies;
        else
            state.companies.push(action.payload);


    }

    function _deleteCompany(state, action) {
        state.companies.filter((company) => company.id !== action.payload.id);
    }

}

function createExtraActions() {

    return {
        // getCompany: getCompany(),
        addCompany: addCompany(),
        deleteCompany: deleteCompany(),
        listCompanies: listCompanies()
    };

    // function getCompany() {
    //     return createAsyncThunk(
    //         `${name}/company/get`,
    //         async ({inn}) => await fetchWrapper.get(`${baseUrl}/company/` + inn, false)
    //     );
    // }


    function listCompanies() {
        return createAsyncThunk(
            `${name}/company/list`,
            async () => await fetchWrapper.post(`${baseUrl}/company/list`, {pageOffset: 0, pageSize: 1000, searchText: ''})
        );
    }

    function addCompany() {

        return createAsyncThunk(
            `${name}/company/save`,
            async function (company, {dispatch}) {
                try {
                    const _company = await fetchWrapper.post(`${baseUrl}/company/save`, company);
                    dispatch(companiesActions._addCompany(_company.body));
                } catch (error) {
                    console.error(error);
                }
            }
        );

    }

    function deleteCompany() {
        return createAsyncThunk(
            `${name}/company/delete`,
            async function ({inn}, {dispatch}) {
                try {
                    const _company = await fetchWrapper.delete(`${baseUrl}/company/` + inn, null);
                    dispatch(companiesActions._deleteCompany(_company.body));
                    dispatch(companiesActions.listCompanies());
                } catch (error) {
                    console.error(error);

                }
            }
        );
    }

}

function createExtraReducers() {

    return {
        // ...getCompany(),
        ...listCompanies()
    };

    // function getCompany(inn) {
    //
    //     let {
    //         pending,
    //         fulfilled,
    //         rejected
    //     } = extraActions.getCompany(inn);
    //
    //     return {
    //         [pending]: (state) => {
    //             state.company = {loading: true};
    //         },
    //         [fulfilled]: (state, action) => {
    //             state.company = action.payload;
    //         },
    //         [rejected]: (state, action) => {
    //             state.company = {error: action.error};
    //         }
    //     };
    //
    // }

    function listCompanies() {

        let {
            pending,
            fulfilled,
            rejected
        } = extraActions.listCompanies;

        return {
            [pending]: (state) => {
                state.companies = {loading: true};
            },
            [fulfilled]: (state, action) => {
                state.companies = action.payload.list;
            },
            [rejected]: (state, action) => {
                state.companies = {error: action.error};
            }
        };

    }

}
