import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {driversActions} from "../_store";
import {CompanyListModal} from "../company/CompanyListModal";
import {PaginationControl} from 'react-bootstrap-pagination-control';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './driver.css'
import {DriverInfo} from "./DriverInfo";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export {Drivers};

function Drivers() {

    const dispatch = useDispatch();
    const {drivers} = useSelector(x => x.drivers);
    const [currDriver, setCurrDriver] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [isShow, invokeModal] = useState(false);
    const [showDriverInfo, setShowDriverInfo] = useState(false);
    const [selDrivers, setSelDrivers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pageOffset, setPageOffset] = useState(1);
    const [pageOffsetReg, setPageOffsetReg] = useState(1);
    const [pageOffsetInactive, setPageOffsetInactive] = useState(1);
    const [pageOffsetDeleted, setPageOffsetDeleted] = useState(1);
    const [startDateRegistered, setStartDateRegistered] = useState(null);
    const [endDateRegistered, setEndDateRegistered] = useState(null);
    const [startDateInactive, setStartDateInactive] = useState(null);
    const [endDateInactive, setEndDateInactive] = useState(new Date());

    const [startDateDeleted, setStartDateDeleted] = useState(null);
    const [endDateDeleted, setEndDateDeleted] = useState(null);

    const pageSize = 10;

    const titleNewDrivers = "Новые (запросы на регистрацию)";
    const titleActiveDrivers = "Активные";
    const titleInactiveDrivers = "Не активные";
    const titleDeletedDrivers = "Удаленные";

    const notify = (text) => toast(text);

    const refreshData = (sti) => {

        if (sti === 0)
            dispatch(driversActions.listNewDrivers({
                pageOffset: pageOffset,
                pageSize: pageSize,
                searchText: searchText
            }));

        if (sti === 1)
            dispatch(driversActions.listRegisteredDrivers({
                pageOffset: pageOffsetReg,
                pageSize: pageSize,
                searchText: searchText,
                startDate: startDateRegistered,
                endDate: endDateRegistered
            }));

        if (sti === 2)
            dispatch(driversActions.listInactiveDrivers({
                pageOffset: pageOffsetInactive,
                pageSize: pageSize,
                searchText: searchText,
                startDate: startDateInactive,
                endDate: endDateInactive
            }));

        if (sti === 3)
            dispatch(driversActions.listDeletedDrivers({
                pageOffset: pageOffsetDeleted,
                pageSize: pageSize,
                searchText: searchText,
                startDate: startDateDeleted,
                endDate: endDateDeleted
            }));

        setSelDrivers([]);
        setShowDriverInfo(false);

    }

    useEffect(() => {
        localStorage.setItem('selectedDrivers', JSON.stringify([]));
        refreshData(tabIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageOffset, pageOffsetReg, pageOffsetInactive, pageOffsetDeleted, tabIndex]);

    const listDrivers = (selectedTabIndex) => {
        setTabIndex(selectedTabIndex);
    }

    function driverHandler(driver) {
        setCurrDriver(driver);
    }

    const selectUnselectDriver = (e, driver) => {
        const {terminalId, guidRequest} = driver;
        if (e.target.checked) {
            dispatch(driversActions._selectDriver({terminalId, guidRequest}));
        } else {
            dispatch(driversActions._unselectDriver({terminalId, guidRequest}));
        }
        const _selDrivers = JSON.parse(localStorage.getItem('selectedDrivers'));
        setSelDrivers(_selDrivers);
    }

    const handleInactiveDate = (date) => {
        setStartDateInactive(date);
        dispatch(driversActions.listInactiveDrivers({
            pageOffset: pageOffsetInactive,
            pageSize: pageSize,
            searchText: searchText,
            startDate: date,
            endDate: endDateInactive
        }));

    }

    const initModal = () => {
        const _selDrivers = JSON.parse(localStorage.getItem('selectedDrivers'));
        if (_selDrivers.length > 0) {
            if (isShow) {
                dispatch(driversActions._unselectAllDrivers([]));
                dispatch(driversActions.listNewDrivers({pageOffset: pageOffset, pageSize: pageSize, searchText: searchText}));
            }
            return invokeModal(!isShow);
        }
    }

    const initModalDriverInfo = () => {

        // if (!showDriverInfo) {
        //     dispatch(driversActions.getDriver(currDriver.id));
        // } else
        //     refreshData(tabIndex);
        if (showDriverInfo)
            refreshData(tabIndex);

        return setShowDriverInfo(!showDriverInfo);
    }

    const handleSearchText = (e) => {
        const st = e.target.value;
        setSearchText(st);
        refreshData(tabIndex);
    }

    const handleStartDateRegistered = (date) => {
        setStartDateRegistered(date);
        dispatch(driversActions.listRegisteredDrivers({
            pageOffset: pageOffsetReg,
            pageSize: pageSize,
            searchText: searchText,
            startDate: date,
            endDate: endDateRegistered
        }));
    }

    const handleEndDateRegistered = (date) => {
        setEndDateRegistered(date);
        dispatch(driversActions.listRegisteredDrivers({
            pageOffset: pageOffsetReg,
            pageSize: pageSize,
            searchText: searchText,
            startDate: startDateRegistered,
            endDate: date
        }));
    }


    const showDriverDialogWindow = (driver) => {
        setCurrDriver(driver);
        setShowDriverInfo(!showDriverInfo);
    }

    function handleStartDateDeleted(date) {
        setStartDateDeleted(date);
        dispatch(driversActions.listDeletedDrivers({
            pageOffset: pageOffsetDeleted,
            pageSize: pageSize,
            searchText: searchText,
            startDate: date,
            endDate: endDateDeleted
        }));

    }

    function handleEndDateDeleted(date) {
        setEndDateDeleted(date);
        dispatch(driversActions.listDeletedDrivers({
            pageOffset: pageOffsetDeleted,
            pageSize: pageSize,
            searchText: searchText,
            startDate: startDateDeleted,
            endDate: date
        }));
    }

    return (
        <div style={{width: `100%`, padding: `0`}}>
            <h3>Drivers: </h3>
            <div className="row">
                <div style={{width: `100%`, padding: `0`}}>
                    <button className="btn btn-link text-decoration-none"
                            style={(tabIndex === 0) ? {float: `left`, borderBottom: `1px solid blue`, borderRadius: `0`} : {float: `left`, borderRadius: `0`}}
                            onClick={() => listDrivers(0)}>{titleNewDrivers}
                    </button>
                    <button className="btn btn-link text-decoration-none"
                            style={(tabIndex === 1) ? {float: `left`, borderBottom: `1px solid blue`, borderRadius: `0`} : {float: `left`, borderRadius: `0`}}
                            onClick={() => listDrivers(1)}>{titleActiveDrivers}
                    </button>
                    <button className="btn btn-link text-decoration-none"
                            style={(tabIndex === 2) ? {float: `left`, borderBottom: `1px solid blue`, borderRadius: `0`} : {float: `left`, borderRadius: `0`}}
                            onClick={() => listDrivers(2)}>{titleInactiveDrivers}
                    </button>
                    <button className="btn btn-link text-decoration-none"
                            style={(tabIndex === 3) ? {float: `left`, borderBottom: `1px solid blue`, borderRadius: `0`} : {float: `left`, borderRadius: `0`}}
                            onClick={() => listDrivers(3)}>{titleDeletedDrivers}
                    </button>
                </div>
            </div>
            <div className="row mt-3">
                <div style={{width: `100%`, padding: `0`}}>
                    <div style={{width: `100%`, height: `50px`}}>

                        {
                            (tabIndex === 0) &&
                            <div style={{float: `left`}}>
                                <button className="btn btn-sm btn-primary ms-1" onClick={() => initModal()}>Зарегистрировать драйвер</button>
                            </div>
                        }

                        {
                            (tabIndex === 1) &&
                            <div>
                                <div style={{float: `left`, margin: `5px`}}>
                                    Зарегистрированы за период:
                                </div>
                                <div style={{position: `absolute`, fontSize: `11px`, fontStyle: `italic`, left: `20px`, top: `200px`, color: `blue`}}>(Created period)</div>
                                <div style={{float: `left`}}>
                                    <DatePicker
                                        className="fdm-datepicker"
                                        showIcon
                                        isClearable
                                        dateFormat="dd.MM.yyyy"
                                        selected={startDateRegistered}
                                        todayButton="Today"
                                        onChange={(date) => handleStartDateRegistered(date)}
                                    />
                                </div>
                                <div style={{float: `left`}}>
                                    <DatePicker
                                        className="fdm-datepicker"
                                        showIcon
                                        isClearable
                                        dateFormat="dd.MM.yyyy"
                                        selected={endDateRegistered}
                                        minDate={startDateRegistered}
                                        todayButton="Today"
                                        onChange={(date) => handleEndDateRegistered(date)}
                                    />
                                </div>
                            </div>
                        }

                        {
                            (tabIndex === 2) &&
                            <div>
                                <div style={{float: `left`, margin: `5px`}}>
                                    Дата:
                                </div>
                                <div style={{float: `left`}}>
                                    <DatePicker
                                        className="fdm-datepicker"
                                        showIcon
                                        isClearable
                                        dateFormat="dd.MM.yyyy"
                                        selected={startDateInactive}
                                        todayButton="Today"
                                        onChange={(date) => handleInactiveDate(date)}
                                    />
                                </div>
                            </div>
                        }

                        {
                            (tabIndex === 3) &&
                            <div>
                                <div style={{float: `left`, margin: `5px`}}>
                                    Удалены за период:
                                </div>
                                <div style={{float: `left`}}>
                                    <DatePicker
                                        className="fdm-datepicker"
                                        showIcon
                                        isClearable
                                        dateFormat="dd.MM.yyyy"
                                        selected={startDateDeleted}
                                        todayButton="Today"
                                        onChange={(date) => handleStartDateDeleted(date)}
                                    />
                                </div>
                                <div style={{float: `left`}}>
                                    <DatePicker
                                        className="fdm-datepicker"
                                        showIcon
                                        isClearable
                                        dateFormat="dd.MM.yyyy"
                                        selected={endDateDeleted}
                                        minDate={startDateDeleted}
                                        todayButton="Today"
                                        onChange={(date) => handleEndDateDeleted(date)}
                                    />
                                </div>
                            </div>
                        }


                        <span style={{float: `right`, fontStyle: `italic`}}>
                            {(tabIndex === 0) && titleNewDrivers}
                            {(tabIndex === 1) && titleActiveDrivers}
                            {(tabIndex === 2) && titleInactiveDrivers}
                            {(tabIndex === 3) && titleDeletedDrivers}
                        </span>
                    </div>
                    <div style={{width: `100%`, height: `50px`}}>
                        <input className="form-control" style={{borderRadius: `0`}} onChange={(e) => handleSearchText(e)}
                               value={searchText}
                               placeholder={
                                   (tabIndex === 0)
                                       ? "Поиск по номеру терминала, серийному номеру фискального модуля"
                                       : "Поиск по ИНН, названию компании, номеру терминала, серийному номеру фискального модуля"
                               }
                        />
                    </div>
                    <div style={{width: `100%`}}>
                        {drivers.list &&
                            <div style={{width: `100%`, padding: `0`}}>
                                <table className="table table-sm table-bordered table-striped table-hover" style={{fontSize: `15px`}}>
                                    <thead>
                                    <tr>
                                        {tabIndex === 0 &&
                                            <td className="table-header-row" style={{width: `20px`}}>&nbsp;</td>
                                        }
                                        <td className="table-header-row" style={{width: `50px`}}>
                                            <div style={{textAlign: `center`}}>ID</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `100px`}}>
                                            <div style={{textAlign: `center`}}>Company IDN</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `135px`}}>
                                            <div style={{textAlign: `center`}}>Created</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `135px`}}>
                                            <div style={{textAlign: `center`}}>Last update</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `330px`}}>
                                            <div style={{textAlign: `center`}}>Request GUID</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `330px`}}>
                                            <div style={{textAlign: `center`}}>Confirm GUID</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `130px`}}>
                                            <div style={{textAlign: `center`}}>Terminal ID</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `130px`}}>
                                            <div style={{textAlign: `center`}}>Fiscal S/N</div>
                                        </td>
                                        <td className="table-header-row">
                                            <div style={{textAlign: `center`}}>ZReport count</div>
                                        </td>
                                        <td className="table-header-row">
                                            <div style={{textAlign: `center`}}>ZReport max count</div>
                                        </td>
                                        <td className="table-header-row">
                                            <div style={{textAlign: `center`}}>Driver name</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `75px`}}>
                                            <div style={{textAlign: `center`}}>Time offline</div>
                                        </td>
                                        <td className="table-header-row" style={{width: `75px`}}>
                                            <div style={{textAlign: `center`}}>Enabled</div>
                                        </td>
                                        {tabIndex === 2 && <td className="table-header-row" style={{width: `75px`}}>
                                            <div style={{textAlign: `center`}}>Inactive days</div>
                                        </td>}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {drivers.list.map(driver =>
                                        <tr key={driver.id}
                                            onClick={() => driverHandler(driver)}
                                            style={driver.id === currDriver.id ? {cursor: `pointer`, borderBottom: `3px solid blue`} : {
                                                cursor: `pointer`,
                                                borderBottomWidth: `3px`
                                            }}
                                            onDoubleClick={() => showDriverDialogWindow(driver)}
                                        >
                                            {tabIndex === 0 && <td>
                                                <input className="form-check" type="checkbox"
                                                       onClick={(e) => selectUnselectDriver(e, driver)}/>
                                            </td>}
                                            <td style={driver.enabled ? {color: `black`, textAlign: `center`} : {color: `darkgray`, textAlign: `center`}}>{driver.id}</td>
                                            <td style={driver.enabled ? {color: `black`, textAlign: `center`} : {color: `darkgray`, textAlign: `center`}}>{driver.inn}</td>
                                            <td style={driver.enabled ? {color: `black`, textAlign: `center`} : {color: `darkgray`, textAlign: `center`}}>{
                                                new Date(driver.instime).toLocaleString('ru-RU', {
                                                    dateStyle: 'short',
                                                    timeStyle: 'short'
                                                })
                                            }</td>
                                            <td style={driver.enabled ? {color: `black`, textAlign: `center`} : {color: `darkgray`, textAlign: `center`}}>{
                                                new Date(driver.updtime).toLocaleString('ru-RU', {
                                                    dateStyle: 'short',
                                                    timeStyle: 'short'
                                                })
                                            }</td>
                                            <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.guidRequest}</td>
                                            <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.guidConfirm}</td>
                                            <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.terminalId}</td>
                                            <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.fiscalSn}</td>
                                            <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.zreportCount}</td>
                                            <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.zreportMaxCount}</td>
                                            <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.driverName}</td>
                                            <td style={driver.enabled ? {color: `black`, textAlign: `center`} : {color: `darkgray`, textAlign: `center`}}>{driver.timeOffline}</td>
                                            <td style={driver.enabled ? {color: `black`, textAlign: `center`} : {
                                                color: `darkgray`,
                                                textAlign: `center`
                                            }}>{driver.enabled ? 'вкл' : 'выкл'}</td>
                                            {tabIndex === 2 && <td style={driver.enabled ? {color: `black`} : {color: `darkgray`}}>{driver.inactiveDays}</td>}
                                        </tr>
                                    )}
                                    </tbody>
                                </table>

                                {/****************************/}
                                {/**** Pagination section ****/}
                                {/****************************/}
                                {
                                    (tabIndex === 0) && <div>
                                        <PaginationControl page={pageOffset}
                                                           between={5}
                                                           total={drivers.pageOption.rowCount}
                                                           limit={pageSize}
                                                           last={true}
                                                           changePage={(page) => {
                                                               setPageOffset(page)
                                                           }}/>
                                        <span>total rows: {drivers.pageOption.rowCount}, rows per page: {pageSize}</span>
                                    </div>
                                }
                                {
                                    (tabIndex === 1) && <div>
                                        <PaginationControl page={pageOffsetReg}
                                                           between={5}
                                                           total={drivers.pageOption.rowCount}
                                                           limit={pageSize}
                                                           last={true}
                                                           changePage={(page) => {
                                                               setPageOffsetReg(page)
                                                           }}/>
                                        <span>total rows: {drivers.pageOption.rowCount}, rows per page: {pageSize}</span>
                                    </div>
                                }
                                {
                                    (tabIndex === 2) && <div>
                                        <PaginationControl page={pageOffsetInactive}
                                                           between={5}
                                                           total={drivers.pageOption.rowCount}
                                                           limit={pageSize}
                                                           last={true}
                                                           changePage={(page) => {
                                                               setPageOffsetInactive(page)
                                                           }}/>
                                        <span>total rows: {drivers.pageOption.rowCount}, rows per page: {pageSize}</span>
                                    </div>
                                }
                                {
                                    (tabIndex === 3) && <div>
                                        <PaginationControl page={pageOffsetDeleted}
                                                           between={5}
                                                           total={drivers.pageOption.rowCount}
                                                           limit={pageSize}
                                                           last={true}
                                                           changePage={(page) => {
                                                               setPageOffsetDeleted(page)
                                                           }}/>
                                        <span>total rows: {drivers.pageOption.rowCount}, rows per page: {pageSize}</span>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <CompanyListModal isShow={isShow} initModal={initModal} selDrivers={selDrivers} selDriver={null} notifyMsg={notify}/>
            <DriverInfo showDriverInfo={showDriverInfo} initModalDriverInfo={initModalDriverInfo} currDriver={currDriver}
                        notifyMsg={notify}
                        selectedTabIndex={tabIndex}/>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={"light"}
            />
        </div>

    )
}