import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '_helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

// create slice
const name = 'users';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

// exports
export const usersActions = {...slice.actions, ...extraActions};
export const usersReducer = slice.reducer;

// implementations
function createInitialState() {
    return {
        user: {},
        users: []
    }
}

function createReducers() {

    return {
        _addUser,
        _deleteUser
    }

    function _addUser(state, action) {

        let b = true;

        const _users = state.users.map(user => {

            if (action.payload.id != null && user.id === action.payload.id) {
                user = action.payload;
                b = false;
            }
            return user;
        });

        if (!b)
            state.users = _users;
        else
            state.users.push(action.payload);

    }

    function _deleteUser(state, action) {
        state.users.filter((user) => user.id !== action.payload.id);
    }

}

function createExtraActions() {

    return {
        addUser: addUser(),
        deleteUser: deleteUser(),
        getAll: getAll()
    };

    function getAll() {
        return createAsyncThunk(
            `${name}/user/list`,
            async () => await fetchWrapper.post(`${baseUrl}/user/list`, {pageOffset: 0, pageSize: 1000, searchText: ''})
        );
    }

    function addUser() {

        return createAsyncThunk(
            `${name}/user/save`,
            async function (user, {dispatch}) {
                try {
                    // console.log('_user old', user);
                    const _user = await fetchWrapper.post(`${baseUrl}/user/save`, user);
                    // console.log('_user new', _user);
                    dispatch(usersActions._addUser(_user.body));
                } catch (error) {
                    console.error(error);
                }
            }
        );

    }

    function deleteUser() {
        return createAsyncThunk(
            `${name}/user/delete`,
            async function ({id}, {dispatch}) {
                try {
                    const _user = await fetchWrapper.delete(`${baseUrl}/user/` + id, null);
                    dispatch(usersActions._deleteUser(_user.body));
                    dispatch(usersActions.getAll());
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

}

function createExtraReducers() {

    return {
        ...getAll()
    };


    function getAll() {

        let {
            pending,
            fulfilled,
            rejected
        } = extraActions.getAll;

        return {
            [pending]: (state) => {
                state.users = {loading: true};
            },
            [fulfilled]: (state, action) => {
                state.users = action.payload.list;
            },
            [rejected]: (state, action) => {
                state.users = {error: action.error};
            }
        };

    }

}
