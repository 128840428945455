import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {companiesActions, driversActions} from "../_store";

export {CompanyListModal};


function CompanyListModal({isShow, initModal, selDrivers, selDriver, notifyMsg}) {

    const dispatch = useDispatch();
    const {companies} = useSelector(x => x.companies);
    const [workOffline, setWorkOffline] = useState(30);


    useEffect(() => {
        dispatch(companiesActions.listCompanies());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function selectCompany(company) {
        if (selDrivers !== null) {
            const drivers = selDrivers.map(driver => {
                return {
                    guidRequest: driver.guidRequest,
                    inn: company.inn,
                    workOffline: workOffline
                };
            });
            dispatch(driversActions.confirmDrivers({drivers}));
            notifyMsg('Driver(s) registered successfully!');
            initModal(false);
        }
        if (selDriver !== null) {
            initModal({inn: company.inn});
        }
    }

    return (
        <div>
            <Modal show={isShow} className="modal-xl">
                <Modal.Header closeButton onClick={initModal}>
                    <Modal.Title>Companies list</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {companies.length &&
                        <div>
                            <div className="row mb-1">
                                <label>Work offline (in days):
                                    <input className="form-control form-control-sm" type="text" name="workOffline"
                                           defaultValue={workOffline}
                                           style={{width: `100px`}} onChange={(e) => setWorkOffline(e.target.value)}/>
                                </label>
                            </div>
                            <div className="row mb-3 p-2 company-list-container">
                                <table className="table table-sm table-bordered table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <td className="table-header-row">Company IDN</td>
                                        <td className="table-header-row">Company name</td>
                                        <td className="table-header-row">Address</td>
                                        <td className="table-header-row">Time offline</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {companies.map(company =>
                                        <tr key={company.id} onDoubleClick={() => selectCompany(company)}>
                                            <td>{company.inn}</td>
                                            <td>{company.companyName}</td>
                                            <td>{company.address}</td>
                                            <td>{company.timeOffline}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={initModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}