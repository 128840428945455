import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {history} from '_helpers';
import {Nav, PrivateRoute} from '_components';
import {Login} from 'login';
import {Users} from "./users";
import {Companies} from "./company";
import {Drivers} from "./driver";

export {App};

function App() {

    // init custom history object to allow navigation from
    // anywhere in the React app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="app-container">
            <Nav/>
            <div className="pt-4 pb-4" style={{width:`100%`, padding:`20px`}}>
                <Routes>
                    {/*
                    <Route path="/"
                           element={
                               <PrivateRoute>
                                   <Home/>
                               </PrivateRoute>
                           }
                    />
*/}
                    <Route path="/drivers"
                           element={
                               <PrivateRoute>
                                   <Drivers/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/companies"
                           element={
                               <PrivateRoute>
                                   <Companies/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/users"
                           element={
                               <PrivateRoute>
                                   <Users/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/login" element={<Login/>}/>
                    <Route path="*" element={<Navigate to="/drivers"/>}/>
                </Routes>
            </div>
        </div>
    );
}
