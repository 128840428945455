import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {StompSessionProvider, useSubscription} from "react-stomp-hooks";
import {authActions} from '_store';
import {useEffect, useState} from "react";
import './nav.css';

export {Nav};

function Nav() {

    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    if (!authUser) return null;

    return (
        <nav className="navbar navbar-expand" style={{color: `white`, backgroundColor: `rgba(0, 0, 0, 0.62)`}}>
            <StompSessionProvider
                connectHeaders={
                    {
                        Authorization: 'Bearer ' + authUser.token
                    }
                }
                reconnectDelay={60000}
                url={baseUrl + "/websocket/rkeeper"}
                //All options supported by @stomp/stompjs can be used here
                debug={(str) => {
                    //console.log(str);
                }}
            >
                <div className="navbar-nav">
                    {/*<NavLink to="/" className="nav-item nav-link app-menu" >Home</NavLink>*/}
                    <NavLink to="/drivers" className="nav-item nav-link"><Subscribing/></NavLink>
                    <NavLink to="/companies" className="nav-item nav-link app-menu">Companies</NavLink>
                    <NavLink to="/users" className="nav-item nav-link app-menu">Users</NavLink>
                </div>
                <div className="navbar-nav position-absolute" style={{right: `10px`}}>
                    <button onClick={logout} className="btn btn-link nav-item nav-link" style={{color: `chocolate`, paddingTop: `6px`}}>Logout {authUser.firstName}</button>
                </div>
            </StompSessionProvider>
        </nav>
    );
}

export function Subscribing() {

    const [lastMessage, setLastMessage] = useState("");
    const [messageCount, setMessageCount] = useState(0);

    //Subscribe to /topic/test, and use handler for all received messages
    //Note that all subscriptions made through the library are automatically removed when their owning component gets unmounted.
    //If the STOMP connection itself is lost they are however restored on reconnect.
    //You can also supply an array as the first parameter, which will subscribe to all destinations in the array
    useSubscription("/topic/rkeeper", (message) => setLastMessage(message.body));

    useEffect(() => {
        //console.log('lastMessage', lastMessage);
        if (lastMessage !== "") {
            const lstm = JSON.parse(lastMessage);
            //console.log('message_message', lstm);
            setMessageCount(lstm.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage]);

    return (
        <div className="app-menu">
            Drivers
            <span className="badge badge-notify">{messageCount === 0 ? "" : messageCount}</span>
        </div>
    );

}
