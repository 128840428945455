import {useEffect, useState} from "react";
import {usersActions} from "../_store";
import {useDispatch} from "react-redux";


export {UserForm};

function UserForm({user}) {

    const userEmpty = {
        "id": null,
        "username": '',
        "email": '',
        "firstName": '',
        "lastName": '',
        "roles": [
            "ROLE_ADMIN"
        ]
    }

    const dispatch = useDispatch();
    const [viewSecurity, setViewSecurity] = useState(false);
    const [currUser, setCurrUser] = useState(userEmpty);
    const [password, setPassword] = useState(user.password);
    const [repeatPassword, setRepeatPassword] = useState(user.password);

    useEffect(() => {
        setCurrUser(user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    function changeSecurityHandle() {
        setViewSecurity(!viewSecurity);
    }

    const changeUserDataHandle = (e) => {
        const {name, value} = e.target;
        setCurrUser({...currUser, [name]: value})
    }

    const changePassword = (e) => {
        const {name, value} = e.target;
        if (name === 'password')
            setPassword(value);
        if (name === 'repeatPassword')
            setRepeatPassword(value);
    }


    function saveUser() {

        console.log(currUser, viewSecurity, password, repeatPassword);

        if (viewSecurity
            && (password === undefined || repeatPassword === undefined || password.trim() === '' || password !== repeatPassword)) {
            alert('Пароль не указан либо указан некорректно');
            return;
        }

        const user = {
            "id": currUser.id,
            "username": currUser.username,
            "email": currUser.email,
            "firstName": currUser.firstName,
            "lastName": currUser.lastName,
            "password": password,
            "roles": [
                "ROLE_ADMIN"
            ]
        }
        dispatch(usersActions.addUser(user));
    }

    function deleteUser() {
        //console.log(currUser, viewSecurity, password, repeatPassword);
        if (viewSecurity
            && (currUser === undefined || currUser.id === undefined || currUser.id <= 0))
            alert('Пользователь не указан либо указан некорректно');
        else {
            const {id} = currUser;
            dispatch(usersActions.deleteUser({id}));
        }
    }

    return (
        <div style={{width: `400px`}}>
            <div className="container-fluid">
                <table>
                    <tbody>
                    <tr>
                        <td style={{width: `150px`}}>Username:</td>
                        <td><input className="form-control form-control-sm" value={currUser?.username} name="username" onChange={changeUserDataHandle}/></td>
                    </tr>
                    <tr>
                        <td style={{width: `150px`}}>First name:</td>
                        <td><input className="form-control form-control-sm" value={currUser?.firstName} name="firstName" onChange={changeUserDataHandle}/></td>
                    </tr>
                    <tr>
                        <td style={{width: `150px`}}>Last name:</td>
                        <td><input className="form-control form-control-sm" value={currUser?.lastName} name="lastName" onChange={changeUserDataHandle}/></td>
                    </tr>
                    <tr>
                        <td style={{paddingTop: `20px`, width: `150px`}}>Security:</td>
                        <td style={{paddingTop: `20px`}}><input type="checkbox" className="form-checkbox" checked={viewSecurity} onChange={changeSecurityHandle}/></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {viewSecurity && <div>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td style={{width: `150px`}}>Password:</td>
                                        <td><input className="form-control form-control-sm" type="password" name="password" onChange={changePassword}/></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: `150px`}}>Re-enter password:</td>
                                        <td><input className="form-control form-control-sm" type="password" name="repeatPassword" onChange={changePassword}/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="container-fluid">
                <button className="btn btn-sm btn-primary user-mng-btn" onClick={saveUser}>Сохранить</button>
                <button className="btn btn-sm btn-danger user-mng-btn" onClick={deleteUser}>Удалить</button>
            </div>
        </div>
    )
}