import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {companiesActions} from "../_store";
import './company.css'
import {CompanyForm} from "./CompanyForm";
import {ICompany} from "../_intf/ICompany";

export {Companies};

function Companies() {

    const companyEmpty: ICompany = {
        "id": null,
        "inn": '',
        "companyName": '',
        "address": '',
        "timeOffline": 30,
        "driverRKeeper": true
    }

    const dispatch = useDispatch();
    const {companies} = useSelector(x => x.companies);
    const [viewDetails, setViewDetails] = useState(false);
    const [currCompany, setCurrCompany] = useState(companyEmpty);

    useEffect(() => {
        dispatch(companiesActions.listCompanies());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const companyHandler = (company) => {
        setViewDetails(true);
        setCurrCompany(company);
    }

    const createNew = () => {
        setViewDetails(true);
        setCurrCompany(companyEmpty);
    }

    return (
        <div style={{width:`100%`, padding:`0`}}>
            <h3>Companies: </h3>
            {companies.length &&
                <div style={{float: `left`, width: `500px`}}>
                    <table className="table table-sm table-bordered table-striped table-hover">
                        <thead>
                        <tr>
                            <td className="table-header-row">Company IDN</td>
                            <td className="table-header-row">Company name</td>
                            <td className="table-header-row">Address</td>
                            <td className="table-header-row">Time offline</td>
                            <td className="table-header-row">R-Keeper</td>
                        </tr>
                        </thead>
                        <tbody>
                        {companies.map(company =>
                            <tr key={company.id}
                                onClick={() => companyHandler(company)}
                                style={company.id === currCompany.id ? {cursor: `pointer`, borderBottom: `3px solid blue`} : {cursor: `pointer`, borderBottomWidth: `3px`}}
                            >
                                <td>{company.inn}</td>
                                <td>{company.companyName}</td>
                                <td>{company.address}</td>
                                <td>{company.timeOffline}</td>
                                <td>{company.driverRKeeper ? 'вкл' : 'выкл'}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            }
            <div style={{float: `left`, marginLeft: `20px`}}>
                <div style={{width: `100%`, marginBottom: `10px`}}>
                    <button className="btn btn-sm btn-success" onClick={createNew}>Добавить новую компанию</button>
                </div>
                {viewDetails && <CompanyForm company={currCompany}/>}
            </div>
            {companies.loading && <div className="spinner-border spinner-border-sm"></div>}
            {companies.error && <div className="text-danger">Error loading companies: {companies.error.message}</div>}

        </div>
    );
}
