import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchWrapper} from "../_helpers";
import {format} from 'date-fns'

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const pageOption = {pageOffset: 1, pageSize: 10, searchText: ''};

//create slice
const name = 'drivers';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

//exports
export const driversActions = {...slice.actions, ...extraActions};
export const driversReducer = slice.reducer;


//implementations
function createInitialState() {
    return {
        drivers: [],
        selectedDrivers: []
    };
}

function createExtraActions() {

    return {
        updateDriver: updateDriver(),
        deleteDriver: deleteDriver(),
        listNewDrivers: listNewDrivers(),
        listRegisteredDrivers: listRegisteredDrivers(),
        listInactiveDrivers: listInactiveDrivers(),
        listDeletedDrivers: listDeletedDrivers(),
        confirmDrivers: confirmDrivers(),
        // getDriver: getDriver()
    };

    function updateDriver() {
        return createAsyncThunk(
            `${name}/drivers/rkeeper/save`,
            async (driver) => await fetchWrapper.post(`${baseUrl}/drivers/rkeeper/save`, driver)
        );

    }

    function deleteDriver() {
        return createAsyncThunk(
            `${name}/drivers/rkeeper/delete`,
            async function ({id}, {dispatch}) {
                try {
                    //console.log('delete driver id', id);
                    await fetchWrapper.delete(`${baseUrl}/drivers/rkeeper/` + id, null);
                    //const _driver = await fetchWrapper.delete(`${baseUrl}/drivers/rkeeper/` + id, null);
                    //dispatch(driversActions._deleteDriver(_driver.body));
                    //dispatch(driversActions.listNewDrivers(pageOption));
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function listNewDrivers() {
        return createAsyncThunk(
            `${name}/drivers/rkeeper/list-new`,
            async function (pageOption) {
                try {
                    //console.log('pageOffset, pageSize, searchText', pageOption);
                    const listDrivers = await fetchWrapper.post(`${baseUrl}/drivers/rkeeper/list-new`, pageOption);
                    //console.log('listDrivers[new]', listDrivers);
                    return listDrivers;
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function listRegisteredDrivers() {
        return createAsyncThunk(
            `${name}/drivers/rkeeper/list-registered`,
            async function (pageOption) {
                try {
                    const {pageOffset, pageSize, searchText, startDate, endDate} = pageOption;
                    const startDate_ = (startDate === null || startDate === undefined) ? null : format(startDate, 'yyyy-MM-dd');
                    const endDate_ = (endDate === null || endDate === undefined) ? null : format(endDate, 'yyyy-MM-dd');
                    const pageOption_ = {
                        pageOffset: pageOffset,
                        pageSize: pageSize,
                        searchText: searchText,
                        startDate: startDate_,
                        endDate: endDate_
                    };
                    return await fetchWrapper.post(`${baseUrl}/drivers/rkeeper/list-registered`, pageOption_);
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function listInactiveDrivers() {
        return createAsyncThunk(
            `${name}/drivers/rkeeper/list-inactive`,
            async function (pageOption) {
                try {
                    const {pageOffset, pageSize, searchText, startDate, endDate} = pageOption;
                    const startDate_ = (startDate === null || startDate === undefined) ? null : format(startDate, 'yyyy-MM-dd');
                    const endDate_ = (endDate === null || endDate === undefined) ? null : format(endDate, 'yyyy-MM-dd');
                    const pageOption_ = {
                        pageOffset: pageOffset,
                        pageSize: pageSize,
                        searchText: searchText,
                        startDate: startDate_,
                        endDate: endDate_
                    };
                    return await fetchWrapper.post(`${baseUrl}/drivers/rkeeper/list-inactive`, pageOption_);
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function listDeletedDrivers() {
        return createAsyncThunk(
            `${name}/drivers/rkeeper/list-deleted`,
            async function (pageOption) {
                try {
                    const {pageOffset, pageSize, searchText, startDate, endDate} = pageOption;
                    const startDate_ = (startDate === null || startDate === undefined) ? null : format(startDate, 'yyyy-MM-dd');
                    const endDate_ = (endDate === null || endDate === undefined) ? null : format(endDate, 'yyyy-MM-dd');
                    const pageOption_ = {
                        pageOffset: pageOffset,
                        pageSize: pageSize,
                        searchText: searchText,
                        startDate: startDate_,
                        endDate: endDate_
                    };
                    return await fetchWrapper.post(`${baseUrl}/drivers/rkeeper/list-deleted`, pageOption_);
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }


    function confirmDrivers() {
        return createAsyncThunk(
            `${name}/drivers/rkeeper/confirm`,
            async function ({drivers}, {dispatch}) {
                try {
                    //console.log('drivers-000', drivers);
                    await fetchWrapper.post(`${baseUrl}/drivers/rkeeper/confirm`, drivers);
                    dispatch(driversActions.listNewDrivers(pageOption));
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    // function getDriver() {
    //     return createAsyncThunk(
    //         `${name}/drivers/rkeeper/get`,
    //         async ({id}) => await fetchWrapper.get(`${baseUrl}/drivers/rkeeper/` + id, null)
    //     );
    // }


}

function createReducers() {
    return {
        _updateDriver,
        _deleteDriver,
        _selectDriver,
        _unselectDriver,
        _unselectAllDrivers
    }

    function _updateDriver(state, action) {

        let b = true;

        const _drivers = state.drivers.map(driver => {

            if (driver.id === action.payload.id) {
                driver = action.payload;
                b = false;
            }
            return driver;
        });

        if (!b)
            state.drivers = _drivers;
        else
            state.drivers.push(action.payload);


    }

    function _deleteDriver(state, action) {
        state.drivers.filter((driver) => driver.id !== action.payload.id);
    }

    function _selectDriver(state, action) {

        let b = true;

        const _selectedDrivers = state.selectedDrivers.map(selectedDriver => {

            if (selectedDriver.guidRequest === action.payload.guidRequest) {
                selectedDriver = action.payload;
                b = false;
            }
            return selectedDriver;
        });

        if (!b)
            state.selectedDrivers = _selectedDrivers;
        else
            state.selectedDrivers.push(action.payload);

        localStorage.setItem('selectedDrivers', JSON.stringify(state.selectedDrivers));

    }

    function _unselectDriver(state, action) {
        state.selectedDrivers = state.selectedDrivers.filter((selectedDriver) => selectedDriver.guidRequest !== action.payload.guidRequest);
        localStorage.setItem('selectedDrivers', JSON.stringify(state.selectedDrivers));
    }

    function _unselectAllDrivers(state, action) {
        state.selectedDrivers = action.payload;
        localStorage.setItem('selectedDrivers', JSON.stringify(action.payload));
    }

}


function createExtraReducers() {

    return {
        ...listNewDrivers(),
        ...listRegisteredDrivers(),
        ...listInactiveDrivers(),
        ...listDeletedDrivers()
    };

    function listNewDrivers() {

        let {
            pending,
            fulfilled,
            rejected
        } = extraActions.listNewDrivers;

        return {
            [pending]: (state) => {
                state.drivers = {loading: true};
            },
            [fulfilled]: (state, action) => {
                // console.log('state[new]', state);
                // console.log('action.payload[new]', action.payload);
                state.drivers = action.payload;
            },
            [rejected]: (state, action) => {
                state.drivers = {error: action.error};
            }
        };

    }

    function listRegisteredDrivers() {

        let {
            pending,
            fulfilled,
            rejected
        } = extraActions.listRegisteredDrivers;

        return {
            [pending]: (state) => {
                state.drivers = {loading: true};
            },
            [fulfilled]: (state, action) => {
                // console.log('state[reg]', state);
                console.log('action.payload[driver reg]', action.payload);
                state.drivers = action.payload;
            },
            [rejected]: (state, action) => {
                state.drivers = {error: action.error};
            }
        };

    }

    function listInactiveDrivers() {

        let {
            pending,
            fulfilled,
            rejected
        } = extraActions.listInactiveDrivers;

        return {
            [pending]: (state) => {
                state.drivers = {loading: true};
            },
            [fulfilled]: (state, action) => {
                // console.log('state[inactive]', state);
                // console.log('action.payload[inactive]', action.payload);
                state.drivers = action.payload;
            },
            [rejected]: (state, action) => {
                state.drivers = {error: action.error};
            }
        };

    }

    function listDeletedDrivers() {
        let {
            pending,
            fulfilled,
            rejected
        } = extraActions.listDeletedDrivers;

        return {
            [pending]: (state) => {
                state.drivers = {loading: true};
            },
            [fulfilled]: (state, action) => {
                state.drivers = action.payload;
            },
            [rejected]: (state, action) => {
                state.drivers = {error: action.error};
            }
        };

    }


}
