import {useEffect, useState} from "react";
import {ICompany} from "../_intf/ICompany";
import {useDispatch} from "react-redux";
import {companiesActions} from "../_store";

export {CompanyForm};

function CompanyForm({company}) {

    const companyEmpty: ICompany = {
        "id": null,
        "inn": '',
        "companyName": '',
        "address": '',
        "timeOffline": 30,
        "driverRKeeper": true
    }

    const dispatch = useDispatch();
    const [currCompany: ICompany, setCurrCompany] = useState(companyEmpty);

    useEffect(() => {
        setCurrCompany(company);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);


    const changeCompanyDataHandle = (e) => {
        const {name, value} = e.target;
        setCurrCompany({...currCompany, [name]: value})
    }

    const changeCompanyRKeeperDriverDataHandle = (e) => {
        const {name, checked} = e.target;
        setCurrCompany({...currCompany, [name]: checked})
    }

    function saveCompany() {
        const company: ICompany = {
            "id": currCompany.id,
            "inn": currCompany.inn,
            "companyName": currCompany.companyName,
            "address": currCompany.address,
            "timeOffline": currCompany.timeOffline,
            "driverRKeeper": currCompany.driverRKeeper
        }
        dispatch(companiesActions.addCompany(company));
    }

    function deleteCompany() {
        dispatch(companiesActions.deleteCompany(currCompany));
    }

    return (
        <div style={{width: `400px`}}>
            <div className="container-fluid">
                <table>
                    <tbody>
                    <tr>
                        <td>Company IDN</td>
                        <td><input className="form-control form-control-sm" name="inn" value={currCompany?.inn} onChange={changeCompanyDataHandle}/></td>
                    </tr>
                    <tr>
                        <td>Company name</td>
                        <td><input className="form-control form-control-sm" name="companyName" value={currCompany?.companyName} onChange={changeCompanyDataHandle}/></td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td><input className="form-control form-control-sm" name="address" value={currCompany?.address} onChange={changeCompanyDataHandle}/></td>
                    </tr>
                    <tr>
                        <td>Time offline</td>
                        <td><input className="form-control form-control-sm" name="timeOffline" value={currCompany?.timeOffline} onChange={changeCompanyDataHandle}/></td>
                    </tr>
                    <tr>
                        <td>R-Keeper</td>
                        <td><input type="checkbox" checked={currCompany?.driverRKeeper} name="driverRKeeper" onChange={changeCompanyRKeeperDriverDataHandle}/></td>
                    </tr>
                    </tbody>
                </table>
                <div style={{paddingTop: `10px`, width: `300px`}}>
                    <button className="btn btn-sm btn-primary user-mng-btn" onClick={saveCompany}>Сохранить</button>
                    <button className="btn btn-sm btn-danger user-mng-btn" onClick={deleteCompany}>Удалить</button>
                </div>
            </div>
        </div>
    );

}