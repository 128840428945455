import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchWrapper} from "../_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

//create slice
const name = 'company';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, extraReducers});

//exports
export const companyActions = {...slice.actions, ...extraActions};
export const companyReducer = slice.reducer;


//implementations
function createInitialState() {
    return {
        company: {
            "id": null,
            "inn": '',
            "companyName": '',
            "address": '',
            "timeOffline": 30,
            "driverRKeeper": true
        }
    }
}

function createExtraActions() {

    return {
        getCompany: getCompany(),
    };

    function getCompany() {
        return createAsyncThunk(
            `${name}/company/get`,
            async ({inn}) => await fetchWrapper.get(`${baseUrl}/company/` + inn, false)
        );
    }

}

function createExtraReducers() {

    return {
        ...getCompany(),
    };

    function getCompany(inn) {

        let {
            pending,
            fulfilled,
            rejected
        } = extraActions.getCompany(inn);

        return {
            [pending]: (state) => {
                state.company = {loading: true};
            },
            [fulfilled]: (state, action) => {
                state.company = action.payload.body;
            },
            [rejected]: (state, action) => {
                state.company = {error: action.error};
            }
        };

    }


}
