import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {driversActions} from "../_store";
import {companyActions} from "../_store/company.slice";
import {ICompany} from "../_intf/ICompany";
import {CompanyListModal} from "../company/CompanyListModal";

export {DriverInfo};

function DriverInfo({showDriverInfo, initModalDriverInfo, currDriver, notifyMsg, selectedTabIndex}) {

    const companyEmpty: ICompany = {
        "id": null,
        "inn": '',
        "companyName": '',
        "address": '',
        "timeOffline": 30,
        "driverRKeeper": true
    }
    const dispatch = useDispatch();
    const [driver, setDriver] = useState(currDriver);
    const [driverCompany, setDriverCompany] = useState(currDriver);
    const [showCompanyList, setShowCompanyList] = useState(false);

    useEffect(() => {
        setDriver(currDriver);
        dispatch(companyActions.getCompany({inn: currDriver.inn})).then(data => {
            console.log('data.payload.body', data.payload.body);
            if (data.payload.body !== null)
                setDriverCompany(data.payload.body);
            else
                setDriverCompany(companyEmpty);
            console.log('COMPANY_111', driverCompany);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currDriver, showDriverInfo, selectedTabIndex]);


    const initModal = ({inn}) => {
        console.log('inn, companyName', inn);
        if (inn !== undefined) {
            dispatch(companyActions.getCompany({inn: inn})).then(data => {
                console.log('data.payload.body', data.payload.body);
                if (data.payload.body !== null) {
                    setDriverCompany(data.payload.body);
                    setDriver({...driver, inn: data.payload.body.inn});
                } else {
                    setDriverCompany(companyEmpty);
                    setDriver({...driver, inn: null});
                }
                console.log('COMPANY_222', driverCompany);
            });
        }

        setShowCompanyList(!showCompanyList);
    }

    const handleDeleteDriver = (id) => {
        dispatch(driversActions.deleteDriver({id})).then(data => {
            initModalDriverInfo();
            notifyMsg('Driver [' + id + '] deleted successfully!');
        })
    }

    const handleChangeDriver = (e) => {
        const {name, value} = e.target;
        setDriver({...driver, [name]: value});
    }

    const handleEnableDriver = (e) => {
        const {name, checked} = e.target;
        setDriver({...driver, [name]: checked});
    }

    const handleSaveDriver = (updDrv) => {
        dispatch(driversActions.updateDriver(updDrv)).then(data => {
            initModalDriverInfo();
            notifyMsg('Driver [' + updDrv.id + '] updated successfully!');
        })
    }

    return (
        <div>
            <Modal show={showDriverInfo} className="modal-lg">
                <Modal.Header closeButton onClick={initModalDriverInfo}>
                    <Modal.Title>Driver info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row p-2">
                            <div style={{width: `100%`}}>
                                <div style={{float: `right`,fontSize:`14px`}}>
                                    Время создания: {
                                    new Date(driver.instime).toLocaleString('ru-RU', {
                                        dateStyle: 'short',
                                        timeStyle: 'short'
                                    })}
                                </div>
                            </div>
                            <div style={{width: `100%`}}>
                                <div style={{float: `right`,fontSize:`14px`}}>
                                    Время последнего обновления: {
                                    new Date(driver.updtime).toLocaleString('ru-RU', {
                                        dateStyle: 'short',
                                        timeStyle: 'short'
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row p-2">
                            <table className="table table-sm table-bordered table-striped table-hover" style={{fontSize: `15px`}}>
                                <tbody>
                                <tr>
                                    <td>Driver ID:</td>
                                    <td>{driver.id}</td>
                                </tr>
                                <tr>
                                    <td>Terminal ID:</td>
                                    <td>{driver.terminalId}</td>
                                </tr>
                                <tr>
                                    <td>Request GUID:</td>
                                    <td>{driver.guidRequest}</td>
                                </tr>
                                <tr>
                                    <td>Confirm GUID:</td>
                                    <td>{driver.guidConfirm}</td>
                                </tr>
                                <tr>
                                    <td>Company:</td>
                                    <td>
                                        <input className="form-control form-control-sm" name="inn" readOnly style={{float: `left`}}
                                               value={driverCompany.inn + ', ' + driverCompany.companyName}/>
                                        <Button className="btn btn-sm" style={{width: `85px`, float: `right`, position: `absolute`, right: `17px`}}
                                                onClick={initModal}>
                                            Изменить
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fiscal S/N:</td>
                                    <td><input className="form-control form-control-sm" name="fiscalSn"
                                               defaultValue={driver.fiscalSn} onChange={(e) => handleChangeDriver(e)}/></td>
                                </tr>
                                <tr>
                                    <td>Driver name:</td>
                                    <td><input className="form-control form-control-sm" name="driverName"
                                               defaultValue={driver.driverName} onChange={(e) => handleChangeDriver(e)}/></td>
                                </tr>
                                <tr>
                                    <td>Time offline:</td>
                                    <td><input className="form-control form-control-sm" name="timeOffline"
                                               defaultValue={driver.timeOffline} onChange={(e) => handleChangeDriver(e)}/></td>
                                </tr>
                                <tr>
                                    <td>Enabled:</td>
                                    <td><input type="checkbox" name="enabled"
                                               defaultChecked={driver.enabled} onChange={(e) => handleEnableDriver(e)}/></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {selectedTabIndex !== 3 && <Button onClick={() => handleSaveDriver(driver)}
                                                       className="btn btn-sm" variant="info" style={{float: `left`, left: `10px`, position: `absolute`}}>
                        Сохранить
                    </Button>}
                    {selectedTabIndex !== 3 && <Button onClick={() => handleDeleteDriver(driver.id)}
                                                       className="btn btn-sm" variant="warning" style={{float: `left`, left: `105px`, position: `absolute`}}>
                        Удалить
                    </Button>}
                    <Button className="btn btn-sm" variant="danger" onClick={initModalDriverInfo} style={{float: `right`}}>
                        Отменить
                    </Button>
                </Modal.Footer>
            </Modal>
            <CompanyListModal isShow={showCompanyList}
                              initModal={initModal}
                              selDrivers={null}
                              selDriver={driver} notifyMsg={notifyMsg}/>
        </div>
    )
}