import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {usersActions} from "../_store";
import {UserForm} from "./UserForm";
import './user.css';

export {Users};

function Users() {

    const userEmpty = {
        "id": null,
        "username": '',
        "email": '',
        "firstName": '',
        "lastName": '',
        "roles": [
            "ROLE_ADMIN"
        ]
    }

    const dispatch = useDispatch();
    const {users} = useSelector(x => x.users);
    const [viewDetails, setViewDetails] = useState(false);
    const [selectedUser, setSelectedUser] = useState(userEmpty);

    useEffect(() => {
        dispatch(usersActions.getAll());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function selectUserHandle(user) {
        setViewDetails(true);
        setSelectedUser(user);
    }

    // const refreshUsers = () => {
    //     dispatch(usersActions.getAll());
    //     setViewDetails(!viewDetails);
    // }

    const createNew = () => {
        setViewDetails(true);
        setSelectedUser(userEmpty);
    }

    return (
        <div style={{width:`100%`, padding:`0`}}>
            <h3>Users: </h3>
            {users.length &&
                <div style={{float: `left`, width: `300px`}}>
                    <table className="table table-sm table-bordered table-striped table-hover">
                        <thead>
                        <tr>
                            <td className="table-header-row">Username</td>
                            <td className="table-header-row">First name</td>
                            <td className="table-header-row">Last name</td>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map(user =>
                            <tr key={user.id} onClick={() => selectUserHandle(user)}
                                style={user.id === selectedUser.id ? {cursor: `pointer`, borderBottom: `3px solid blue`} : {cursor: `pointer`, borderBottomWidth: `3px`}}
                            >
                                <td>
                                    {(user.username === undefined || user.username === null) ? "" : user.username}
                                </td>
                                <td>{(user.firstName === undefined || user.firstName === null) ? "" : user.firstName}</td>
                                <td>{(user.lastName === undefined || user.lastName === null) ? "" : user.lastName}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            }
            <div style={{float: `left`, marginLeft: `20px`}}>
                <div style={{width: `100%`, marginBottom: `10px`}}>
                    <button className="btn btn-sm btn-success" onClick={createNew}>Добавить нового пользователя</button>
                </div>
                {viewDetails && <UserForm user={selectedUser} />}
            </div>
            {users.loading && <div className="spinner-border spinner-border-sm"></div>}
            {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}
        </div>
    )
}