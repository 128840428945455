import {configureStore} from '@reduxjs/toolkit';

import {authReducer} from './auth.slice';
import {usersReducer} from './users.slice';
import {companiesReducer} from "./companies.slice";
import {driversReducer} from "./drivers.slice";
import {companyReducer} from "./company.slice";

export * from './auth.slice';
export * from './users.slice';
export * from './companies.slice';
export * from './drivers.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        companies: companiesReducer,
        company: companyReducer,
        drivers: driversReducer
    },
});